<template>
  <div>
    <v-list disabled>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-stethoscope</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ especialidade }}</v-list-item-title>
          <v-list-item-subtitle>{{ medico }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-calendar-clock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Agendamento</v-list-item-title>
          <v-list-item-subtitle v-if="hora === '00:00'"
            >
            Dia {{ data }} por ordem de chegada
            </v-list-item-subtitle
          >
          <v-list-item-subtitle v-else
            >
            Dia {{ data }} às {{ hora }}
            </v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-container>
      <v-form ref="form" @submit.prevent="agendar()">
      <v-row justify="center">
        <v-col>
          <p style="width: 95%; margin: 15px auto 15px auto">
            <strong
              >Informe os dados a seguir para finalizar o agendamento:</strong
            >
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="cpf"
            v-mask="maskCPF"
            label="CPF"
            :rules="[rules.required]"
            id="cpf"
            outlined
            style="width: 95%; margin: auto"
            color="success"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="nome"
            label="Nome"
            id="nome"
            :rules="[rules.required]"
            outlined
            style="width: 95%; margin: auto"
            color="success"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="telefone"
            v-mask="maskTel"
            :rules="[rules.required, rules.celular]"
            label="Celular"
            id="telefone"
            outlined
            color="success"
            style="width: 95%; margin: auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="btnContainer">
          <v-btn type="submit" :loading="loading">
            <v-img
              src="../assets/IconEcon.png"
              alt="Logo"
              class="img"
              width="28"
              height="28"
            />
            Agendar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    </v-container>
  </div>
</template>
<script>
import BaseService from "../services/BaseService";

export default {
  name: "Agendamento",
  data: () => ({
    especialidade: "",
    medico: "",
    profissional: {},
    data: "",
    hora: "",
    cpf: "",
    nome: "",
    telefone: "",
    idProcedimento:"",
    maskTel: "(##) #####-####",
    maskCPF: "###.###.###-##",
    loading: false,
    rules: {
          required: value => !!value || 'Campo obrigatório.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail inválido.'
          },
          celular: value => {
            const pattern = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/
            return pattern.test(value) || 'Celular inválido.'
          },
        }
  }),
  mounted() {
    let data, horario, dados;
    if(localStorage.getItem("data"))
      data = JSON.parse(localStorage.getItem("data"));
    if(localStorage.getItem("horario"))
      horario = JSON.parse(localStorage.getItem("horario"));
    if(localStorage.getItem("dados"))
      dados = JSON.parse(localStorage.getItem("dados"));
    
    this.data = this.convertData(this.formataData(data), "-");
    this.hora = horario;
    const { profissional, especialidade, idProcedimento } = dados;
    this.profissional = profissional;
    this.medico = profissional.nome;
    this.especialidade = especialidade.Especialidade;
    this.idProcedimento = idProcedimento ? idProcedimento : 0;
  },
  methods: {
    getRawValue(value, mask) {
      const rawValueArray = [];
      mask.split("").forEach((character, i) => {
        if (character === "#") {
          rawValueArray.push(value[i]);
        }
      });

      return rawValueArray.join("");
    },
    async agendar() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line no-unused-vars
        const salvarService = new BaseService(`agendar`);
        try {
          await salvarService.post({
            id: this.profissional.id,
            data: this.data,
            hora: this.hora,
            cpf: this.getRawValue(this.cpf, this.maskCPF),
            nome: this.nome,
            idProcedimento: this.idProcedimento,
            telefone: this.getRawValue(this.telefone, this.maskTel),
          });
          this.$router.push("Obrigado");
        } catch (error) {
          this.$toast.error("Aconteceu um erro! \nO agendamento será reiniciado");
          this.$router.push("Home");
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
      
    },
    convertData(date, separador) {
      if (separador === "-") return date.replace("-", "/").replace("-", "/");
      return date.replace("/", "-").replace("/", "-");
    },
    formataData(date) {
      let data = date.split("-");
      return `${data[2]}-${data[1]}-${data[0]}`;
    },
  },
};
</script>
<style scoped>
.img {
  height: 28px;
  width: 28px;
  margin: 5px;
}
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
</style>